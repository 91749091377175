var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{attrs:{"id":"page-selects"}},[_c('v-dialog',{attrs:{"persistent":"","width":"230"},model:{value:(_vm.dialogShowImage),callback:function ($$v) {_vm.dialogShowImage=$$v},expression:"dialogShowImage"}},[_c('v-card',[_c('v-btn',{attrs:{"color":"pink","dark":"","small":"","absolute":"","right":"","fab":""},nativeOn:{"click":function($event){return _vm.hideToggleLockJobNumber($event)}}},[_c('v-icon',[_vm._v("close")])],1),_c('v-card-text',[_c('v-avatar',{staticClass:"grey lighten-4",attrs:{"tile":true,"size":200}},[_c('img',{attrs:{"src":_vm.utils.imageProfile(_vm.selectedImage),"alt":"","width":"300","height":"300"}})])],1),_c('v-divider')],1)],1),_c('v-container',{staticClass:"pa-2",attrs:{"grid-list-xl":"","fluid":""}},[_c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.headersUser,"items":_vm.vDataTable.data,"search":_vm.vDataTable.search,"fixed-header":"","page":_vm.vDataTable.options.page,"itemsPerPage":_vm.vDataTable.options.itemsPerPage,"options":_vm.vDataTable.options,"height":_vm.$vuetify.breakpoint.height - 220,"footer-props":{
        itemsPerPageText: _vm.$t('itemsPerPageText'),
        itemsPerPageAllText: _vm.$t('itemsPerPageAllText'),
        showFirstLastPage: true,
        showCurrentPage: true,
        firstIcon: 'mdi-arrow-collapse-left',
        lastIcon: 'mdi-arrow-collapse-right',
        prevIcon: 'mdi-minus',
        nextIcon: 'mdi-plus',
      },"item-key":"profile_skey","expanded":_vm.vDataTable.expanded,"show-expand":""},on:{"update:page":function($event){return _vm.$set(_vm.vDataTable.options, "page", $event)},"update:itemsPerPage":function($event){return _vm.$set(_vm.vDataTable.options, "itemsPerPage", $event)},"update:items-per-page":function($event){return _vm.$set(_vm.vDataTable.options, "itemsPerPage", $event)},"update:options":function($event){return _vm.$set(_vm.vDataTable, "options", $event)},"update:expanded":function($event){return _vm.$set(_vm.vDataTable, "expanded", $event)}},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-row',[_c('v-col',{attrs:{"cols":"12","sm":"12"}},[_c('v-toolbar',{attrs:{"flat":""}},[_c('v-toolbar-title',[_vm._v("User Profile")])],1)],1),_c('v-col',{attrs:{"cols":"12","sm":"12"}},[_c('v-toolbar',{attrs:{"flat":""}},[_c('v-dialog',{attrs:{"max-width":"800px","eager":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
      var on = ref.on;
      var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticStyle:{"text-transform":"capitalize"},attrs:{"outlined":"","color":"primary"}},'v-btn',attrs,false),on),[_c('img',{attrs:{"src":require("../assets/SoftwareClinicIcon/New.png"),"height":"30","weight":"30"}}),_vm._v("Add Profile ")])]}}]),model:{value:(_vm.dialog.dialogAddEditWindow),callback:function ($$v) {_vm.$set(_vm.dialog, "dialogAddEditWindow", $$v)},expression:"dialog.dialogAddEditWindow"}},[_c('v-card',[_c('v-card-title',[_c('span',{staticClass:"headline"},[_vm._v(_vm._s(_vm.formTitle))])]),_c('v-card-text',[_c('v-container',[_c('v-row',[_c('v-col',{attrs:{"cols":"12","sm":"12","md":"10"}},[_c('v-text-field',{attrs:{"outlined":"","hide-details":"","label":"Profile Name"},model:{value:(_vm.vDataTable.editedItem.profile_name),callback:function ($$v) {_vm.$set(_vm.vDataTable.editedItem, "profile_name", $$v)},expression:"vDataTable.editedItem.profile_name"}})],1),_c('v-col',{attrs:{"cols":"12","sm":"12","md":"2"}},[_c('v-text-field',{attrs:{"outlined":"","hide-details":"","label":"Abbreviation"},model:{value:(_vm.vDataTable.editedItem.abbreviation),callback:function ($$v) {_vm.$set(_vm.vDataTable.editedItem, "abbreviation", $$v)},expression:"vDataTable.editedItem.abbreviation"}})],1)],1)],1)],1),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"outlined":"","color":"primary"},on:{"click":_vm.save}},[_vm._v(_vm._s(_vm.$t('save')))]),_c('v-btn',{attrs:{"outlined":"","color":"primary"},on:{"click":_vm.close}},[_vm._v(_vm._s(_vm.$t('cancel')))])],1)],1)],1),_c('v-spacer'),_c('v-text-field',{attrs:{"dense":"","outlined":"","append-icon":"mdi-magnify","label":"Search","single-line":"","hide-details":""},model:{value:(_vm.vDataTable.search),callback:function ($$v) {_vm.$set(_vm.vDataTable, "search", $$v)},expression:"vDataTable.search"}})],1)],1)],1)]},proxy:true},{key:"item.actions",fn:function(ref){
      var item = ref.item;
return [_c('v-icon',{staticClass:"mr-2",attrs:{"color":"teal","small":""},on:{"click":function($event){return _vm.editItem(item)}}},[_vm._v("edit")]),_c('v-icon',{attrs:{"color":"pink","small":""},on:{"click":function($event){return _vm.deleteItemConfirm(item)}}},[_vm._v("delete")])]}},{key:"item.data-table-no",fn:function(ref){
      var item = ref.item;
      var index = ref.index;
return [_vm._v(" "+_vm._s(index + 1)+" ")]}},{key:"no-data",fn:function(){return [_c('v-alert',{attrs:{"type":"info","border":"bottom","colored-border":"","elevation":"2"}},[_vm._v(" "+_vm._s(_vm.$t('noData'))+" ")])]},proxy:true},{key:"no-results",fn:function(){return [_c('v-alert',{attrs:{"type":"warning","border":"bottom","colored-border":"","elevation":"2"}},[_vm._v(" "+_vm._s(_vm.stringFormat(_vm.$t('searchNotFound'),_vm.vDataTable.search))+" ")])]},proxy:true},{key:"footer",fn:function(){return [_c('tr',{staticClass:"sticky-table-footer",staticStyle:{"position":"absolute"}},[_c('td',[_vm._v("Selected("+_vm._s(_vm.vDataTable.selected.length)+")s")])])]},proxy:true},{key:"expanded-item",fn:function(ref){
      var headers = ref.headers;
      var item = ref.item;
return [_c('td',{attrs:{"colspan":headers.length}},[_c('v-card',{staticClass:"elevation-10"},[_c('v-card-text',[_c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.subHeaders,"items":item.pl_profile_group,"search":item.searchLanguage,"show-select":"","footer-props":{
                  itemsPerPageText: _vm.$t('itemsPerPageText'),
                  itemsPerPageAllText: _vm.$t('itemsPerPageAllText'),
                  showFirstLastPage: true,
                  showCurrentPage: true,
                  firstIcon: 'mdi-arrow-collapse-left',
                  lastIcon: 'mdi-arrow-collapse-right',
                  prevIcon: 'mdi-minus',
                  nextIcon: 'mdi-plus',
                },"item-key":"group_skey"},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-toolbar',{attrs:{"flat":""}},[_c('v-btn',{staticStyle:{"text-transform":"capitalize"},attrs:{"outlined":"","color":"secondary"},on:{"click":function($event){return _vm.newItemLevel2(item)}}},[_vm._v(" New Group ")]),_c('v-divider',{staticClass:"mx-1",attrs:{"inset":"","vertical":""}}),_c('v-btn',{staticStyle:{"text-transform":"capitalize"},attrs:{"outlined":"","color":"secondary"},nativeOn:{"click":function($event){return _vm.checkDeleteselectedLevel2(item)}}},[_vm._v(" Delete Group ")]),_c('v-spacer'),_c('v-text-field',{attrs:{"outlined":"","append-icon":"search","label":_vm.$t('search'),"single-line":"","hide-details":"","dense":""},model:{value:(item.searchLanguage),callback:function ($$v) {_vm.$set(item, "searchLanguage", $$v)},expression:"item.searchLanguage"}})],1)]},proxy:true},{key:"item.actions",fn:function(ref){
                var item = ref.item;
return [_c('v-icon',{staticClass:"mr-2",attrs:{"color":"pink","small":""},on:{"click":function($event){return _vm.deleteItemLevel2(item)}}},[_vm._v("delete")])]}}],null,true),model:{value:(item.selectedLanguage),callback:function ($$v) {_vm.$set(item, "selectedLanguage", $$v)},expression:"item.selectedLanguage"}})],1)],1)],1)]}}]),model:{value:(_vm.vDataTable.selected),callback:function ($$v) {_vm.$set(_vm.vDataTable, "selected", $$v)},expression:"vDataTable.selected"}}),_c('v-dialog',{attrs:{"persistent":"","width":"300"},model:{value:(_vm.dialog.dialogProgress),callback:function ($$v) {_vm.$set(_vm.dialog, "dialogProgress", $$v)},expression:"dialog.dialogProgress"}},[_c('v-card',{attrs:{"color":"primary","dark":""}},[_c('v-card-text',[_c('span',{staticClass:"font-weight-bold dark white--text"},[_vm._v(_vm._s(_vm.progressMessage))]),_c('v-progress-linear',{staticClass:"mb-0",attrs:{"indeterminate":"","color":"white"}})],1)],1)],1),_c('v-dialog',{attrs:{"persistent":"","max-width":"800px","scrollable":"","eager":""},model:{value:(_vm.dialog.dialogBrowseUserGroupBrowse),callback:function ($$v) {_vm.$set(_vm.dialog, "dialogBrowseUserGroupBrowse", $$v)},expression:"dialog.dialogBrowseUserGroupBrowse"}},[_c('UserGroupBrowse',{ref:"UserGroupBrowse",attrs:{"receiveVar":_vm.sendVar},on:{"eventReturn":_vm.getVarReturn}})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }