<template>
  <div id="page-selects">
    <!-- <h1> {{$t('userMaintTitle')}}</h1> -->
    <v-dialog v-model="dialogShowImage" persistent width="230">
      <v-card>
        <v-btn color="pink" dark small absolute right fab @click.native="hideToggleLockJobNumber">
          <v-icon>close</v-icon>
        </v-btn>
        <v-card-text>
          <v-avatar
          :tile="true"
          :size="200"
          class="grey lighten-4"
          >
            <img :src="utils.imageProfile(selectedImage)" alt="" width="300" height="300">
          </v-avatar>
        </v-card-text>
        <v-divider></v-divider>
      </v-card>
    </v-dialog>
    <v-container grid-list-xl fluid class="pa-2">
      <v-data-table
        v-model="vDataTable.selected"
        :headers="headersUser"
        :items="vDataTable.data"
        :search="vDataTable.search"
        fixed-header
        :page.sync="vDataTable.options.page"
        :itemsPerPage.sync="vDataTable.options.itemsPerPage"
        :options.sync="vDataTable.options"
        :height="$vuetify.breakpoint.height - 220"
        :footer-props="{
          itemsPerPageText: $t('itemsPerPageText'),
          itemsPerPageAllText: $t('itemsPerPageAllText'),
          showFirstLastPage: true,
          showCurrentPage: true,
          firstIcon: 'mdi-arrow-collapse-left',
          lastIcon: 'mdi-arrow-collapse-right',
          prevIcon: 'mdi-minus',
          nextIcon: 'mdi-plus',
        }"
        item-key="profile_skey"
        class="elevation-1"
        :expanded.sync="vDataTable.expanded"
        show-expand>
        <template v-slot:top>
          <v-row>
            <v-col cols="12" sm="12">
            <v-toolbar flat>
              <v-toolbar-title>User Profile</v-toolbar-title>
            </v-toolbar>
            </v-col>
            <v-col cols="12" sm="12">
              <v-toolbar flat>
                <v-dialog v-model="dialog.dialogAddEditWindow" max-width="800px" eager>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn outlined color="primary" v-bind="attrs" v-on="on" style="text-transform: capitalize">
                      <img src="../assets/SoftwareClinicIcon/New.png" height="30" weight="30"/>Add Profile
                    </v-btn>
                  </template>
                  <v-card>
                    <v-card-title>
                      <span class="headline">{{ formTitle }}</span>
                    </v-card-title>
                    <v-card-text>
                      <v-container>
                        <v-row>
                          <v-col cols="12" sm="12" md="10">
                            <v-text-field outlined hide-details label="Profile Name" v-model="vDataTable.editedItem.profile_name"></v-text-field>
                          </v-col>
                          <v-col cols="12" sm="12" md="2">
                            <v-text-field outlined hide-details label="Abbreviation" v-model="vDataTable.editedItem.abbreviation"></v-text-field>
                          </v-col>
                        </v-row>
                      </v-container>
                    </v-card-text>
                    <v-card-actions>
                      <v-spacer></v-spacer>
                        <v-btn outlined color="primary" @click="save">{{$t('save')}}</v-btn>
                        <v-btn outlined color="primary" @click="close">{{$t('cancel')}}</v-btn>
                    </v-card-actions>
                  </v-card>
                </v-dialog>
                <!-- <v-divider class="mx-1" inset vertical></v-divider>
                <v-btn class="text-right" outlined color="primary" style="text-transform: capitalize" @click="resetPassword">
                  <img src="../assets/SoftwareClinicIcon/woman-working-on-a-computer.jpg" height="30" weight="30"/>Reset password
                </v-btn> -->
                <v-spacer></v-spacer>
                <v-text-field
                  dense
                  v-model="vDataTable.search"
                  outlined
                  append-icon="mdi-magnify"
                  label="Search"
                  single-line
                  hide-details>
                </v-text-field>
              </v-toolbar>
            </v-col>
          </v-row>
        </template>
        <template v-slot:item.actions="{ item }">
          <v-icon color="teal" small class="mr-2" @click="editItem(item)">edit</v-icon>
          <v-icon color="pink" small @click="deleteItemConfirm(item)">delete</v-icon>
        </template>
        <template v-slot:item.data-table-no="{ item, index }">
          {{index + 1}}
        </template>
        <template v-slot:no-data>
          <v-alert type="info" border="bottom" colored-border elevation="2">
            {{$t('noData')}}
          </v-alert>
        </template>
        <template v-slot:no-results>
          <v-alert type="warning" border="bottom" colored-border elevation="2">
            {{stringFormat($t('searchNotFound'),vDataTable.search)}}
          </v-alert>
        </template>
        <template v-slot:footer>
          <tr class="sticky-table-footer" style="position: absolute" >
              <td>Selected({{vDataTable.selected.length}})s</td> 
          </tr>
        </template>
        <template v-slot:expanded-item="{ headers, item }"> 
          <td :colspan="headers.length">
            <v-card class="elevation-10">
              <v-card-text>
                <v-data-table v-model="item.selectedLanguage" 
                  :headers="subHeaders"
                  :items="item.pl_profile_group"
                  :search="item.searchLanguage"
                  show-select
                  :footer-props="{
                    itemsPerPageText: $t('itemsPerPageText'),
                    itemsPerPageAllText: $t('itemsPerPageAllText'),
                    showFirstLastPage: true,
                    showCurrentPage: true,
                    firstIcon: 'mdi-arrow-collapse-left',
                    lastIcon: 'mdi-arrow-collapse-right',
                    prevIcon: 'mdi-minus',
                    nextIcon: 'mdi-plus',
                  }"
                  item-key="group_skey"
                  class="elevation-1">
                  <template v-slot:top>
                    <v-toolbar flat>
                      <v-btn outlined color="secondary" style="text-transform: capitalize" 
                        @click="newItemLevel2(item)">
                        New Group
                      </v-btn>
                      <v-divider class="mx-1" inset vertical></v-divider>
                      <v-btn outlined color="secondary" @click.native="checkDeleteselectedLevel2(item)" style="text-transform: capitalize">
                        Delete Group
                      </v-btn>
                      <v-spacer></v-spacer>
                      <v-text-field outlined
                        append-icon="search"
                        :label="$t('search')"
                        single-line
                        hide-details
                        v-model="item.searchLanguage"
                        dense
                      ></v-text-field>
                    </v-toolbar>
                  </template>
                  <template v-slot:item.actions="{ item }">
                    <v-icon color="pink" small class="mr-2" @click="deleteItemLevel2(item)">delete</v-icon>
                  </template>
                </v-data-table>
              </v-card-text>
            </v-card>
          </td>
        </template>
      </v-data-table>
      <v-dialog v-model="dialog.dialogProgress" persistent width="300">
        <v-card color="primary" dark>
          <v-card-text>
            <span class="font-weight-bold dark white--text">{{progressMessage}}</span>
            <v-progress-linear
              indeterminate
              color="white"
              class="mb-0"
            ></v-progress-linear>
          </v-card-text>
        </v-card>
      </v-dialog>
      <v-dialog v-model="dialog.dialogBrowseUserGroupBrowse" persistent max-width="800px" scrollable eager>
        <UserGroupBrowse ref="UserGroupBrowse"
          :receiveVar="sendVar" @eventReturn="getVarReturn">
        </UserGroupBrowse>
      </v-dialog>
    </v-container>
  </div>
</template>

<script>
import Base64Binary from 'base64-arraybuffer'
import VWidget from '@/components/VWidget'
import UserGroupBrowse from '@/components/UserGroupBrowse'
// import { imageProfile } from '../utils'

export default {
  components: {
    UserGroupBrowse
  },

  props: ['parentComponent'],

  data () {
    return {
      utils: require('@/utils'),
      stringFormat: require('string-format'),
      appName: process.env.VUE_APP_DB_APP_NAME,
      dialog: {
        dialogProgress: false,
        dialogAddEditWindow: false,
        dialogBrowseUserGroupBrowse: false,
      },
      showMenu: false,
      x: 0,
      y: 0,
      vDataTable: {
        search: '',
        selected: [],
        data: [],
        editedIndex: -1,
        editedItem: {
          win_name: '',
          window_comment: ''
        },
        defaultItem: {
          win_name: '',
          window_comment: ''
        },
        editedLanguageIndex: -1,
        editedLanguageItem: {
        },
        defaultLanguageItem: {
        },
        options: {
          page: 1,
          itemsPerPage: 10,
          sortBy: [],
          sortDesc: [],
          groupBy: [],
          groupDesc: true,
          multiSort: true,
          // mustSort: true
        },
        expanded: [],
      },
      progressMessage: '',
      appSkey: -1,
      e3: true,
      dialogShowImage: false,
      selectedImage: null,
      dialogAddEditWindow: false,
      dialogProgress: false,
      searchUser: '',
      paginationUser: {
        sortBy: 'profile_skey',
        rowsPerPage: 10
      },
      selectedUser: [],
      dataUser: [],
      editedIndex: -1,
      editedItem: {
        profile_skey: '',
        profile_name: '',
        abbreviation: '',
      },
      deletedIndex: -1,
      deletedItem: {
        profile_skey: '',
        profile_name: '',
        abbreviation: '',
      },
      defaultItem: {
        profile_skey: '',
        profile_name: '',
        abbreviation: '',
      },
      dropDownData: [],
      profileSkeyIndex: -1,
      editedIndexLevel2: -1,
      editedItemLevel2: {},
      deletedIndexLevel2: -1,
      deletedItemLevel2: {},
      sendVar: {
        profileSkey: ''
      },
    }
  },

  computed: {
    formTitle () {
      return this.vDataTable.editedIndex === -1 ? 'New Profile' : 'Edit Profile'
    },
    headersUser () {
      return [
        { text: '#', align: 'center', value: 'data-table-no', class: 'text-xl-left body-1 black--text' },
        { text: this.$t('actions'), sortable: false, value: 'actions', class: 'text-xl-left body-1 black--text' },
        { text: 'Profile Name', value: 'profile_name', align: 'left', class: 'text-xl-left body-1 black--text' },
        { text: 'Abbreviation', value: 'abbreviation', align: 'left', class: 'text-xl-left body-1 black--text' },
      ]
    },
    subHeaders () {
      return [
        { text: this.$t('actions'), sortable: false, value: 'actions', class: 'text-xl-left body-1 black--text', width: '100px' },
        { text: 'Group Name', value: 'group_name' },
      ]
    },
  },

  watch: {
    'dialog.dialogAddEditWindow' (val) {
      val || this.close()
    }
  },

  created: function () {
    this.retrieveUserProfile()
    this.retrieveDropDown()
  },

  mounted () {
  },

  updated () {
  },

  methods: {
    selectLanguage (language) {
      this.vDataTable.data = []
    },
    initialize () {
      this.vDataTable.selected = []
      this.vDataTable.editedIndex = -1
      this.vDataTable.editedItem = {
        profile_skey: '',
        profile_name: '',
        abbreviation: '',
      }
      this.vDataTable.defaultItem = {
        profile_skey: '',
        profile_name: '',
        abbreviation: '',
      }
      this.editedIndexLevel2 = -1
      this.editedItemLevel2 = {}
    },
    showToggleLockJobNumber (picture) {
      this.dialogShowImage = true
      this.selectedImage = picture
    },
    hideToggleLockJobNumber () {
      this.dialogShowImage = false
    },
    async retrieveUserProfile () {
      this.dialog.dialogProgress = true
      this.progressMessage = this.$t('waiting')
      await this.axios.post(process.env.VUE_APP_API + '/user/retrieveUserProfile' + process.env.VUE_APP_DATABASE_TYPE)
        .then(response => {
          if (response.data.isSuccess) {
            this.dialog.dialogProgress = false
            this.vDataTable.data = response.data.data
          } else {
            this.dialog.dialogProgress = false
            this.$swal({type: 'error', title: response.data.reasonText})
          }
        })
        .catch(e => {
          this.dialog.dialogProgress = false
          this.$swal({type: 'error', title: e.message})
        })
    },
    retrieveDropDown () {
      this.axios.post(process.env.VUE_APP_API + '/user/retrieveDropDown' + process.env.VUE_APP_DATABASE_TYPE, {
      })
        .then(response => {
          if (response.data.isSuccess) {
            this.dropDownData = response.data.data[0]
          } else {
            this.$swal({type: 'error', title: response.data.reasonText})
          }
        })
        .catch(e => {
          this.$swal({type: 'error', title: e.message})
        })
    },
    async addUserProfile (profileName, abbreviation) {
      await this.axios.post(process.env.VUE_APP_API + '/user/addUserProfile' + process.env.VUE_APP_DATABASE_TYPE, {
        profile_name: profileName,
        abbreviation: abbreviation,
      })
        .then(response => {
          if (response.data.isSuccess) {
            this.vDataTable.data.push(this.vDataTable.editedItem)
            this.retrieveUserProfile()
            this.$swal({type: 'success', title: response.data.reasonText, showConfirmButton: false, timer: 1500})
          } else {
            this.$swal({type: 'error', title: response.data.reasonText})
          }
        })
        .catch(e => {
          this.$swal({type: 'error', title: e.message})
        })
    },
    async updateUserProfile (profileSkey, profileName, abbreviation) {
      await this.axios.post(process.env.VUE_APP_API + '/user/updateUserProfile' + process.env.VUE_APP_DATABASE_TYPE, {
        profile_skey: profileSkey,
        profile_name: profileName,
        abbreviation: abbreviation,
      })
        .then(response => {
          if (response.data.isSuccess) {
            Object.assign(this.vDataTable.data[this.vDataTable.editedIndex], this.vDataTable.editedItem)
            this.retrieveUserProfile()
            this.$swal({type: 'success', title: response.data.reasonText, showConfirmButton: false, timer: 1500})
          } else {
            this.$swal({type: 'error', title: response.data.reasonText})
          }
        })
        .catch(e => {
          this.$swal({type: 'error', title: e.message})
        })
    },
    deleteUserProfile (item) {
      var profileSkey = this.deletedItem.profile_skey
      this.dataUser.splice(this.deletedIndex, 1)
      profileSkey = item.profile_skey
      this.axios.post(process.env.VUE_APP_API + '/user/deleteUserProfile' + process.env.VUE_APP_DATABASE_TYPE, {
        profile_skey: profileSkey
      })
        .then(response => {
          if (response.data.isSuccess) {
            this.retrieveUserProfile()
            this.$swal({type: 'success', title: response.data.reasonText, showConfirmButton: false, timer: 1500})
          } else {
            this.$swal({type: 'error', title: response.data.reasonText})
          }
        })
        .catch(e => {
          this.$swal({type: 'error', title: e.message})
        })
    },
    toggleAll () {
      if (this.selectedUser.length) this.selectedUser = []
      else this.selectedUser = this.dataUser.slice()
    },
    changeSort (column) {
      if (this.paginationUser.sortBy === column) {
        this.paginationUser.descending = !this.paginationUser.descending
      } else {
        this.paginationUser.sortBy = column
        this.paginationUser.descending = false
      }
    },
    /* editItem (item) {
      this.editedIndex = this.dataUser.indexOf(item)
      this.editedItem = Object.assign({}, item)
      this.dialogAddEditWindow = true
    }, */
    editItem (item) {
      this.vDataTable.editedIndex = this.vDataTable.data.indexOf(item)
      this.vDataTable.editedItem = Object.assign({}, item)
      this.dialog.dialogAddEditWindow = true
    },
    async deleteItemConfirm (item) {
      this.vDataTable.editedIndex = this.vDataTable.data.indexOf(item)
      this.vDataTable.editedItem = Object.assign({}, item)
      this.$swal({
        text: 'คุณต้องการที่จะลบข้อมูล Profile ?',
        type: 'question',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: this.$t('yes'),
        cancelButtonText: this.$t('no')
      }).then(async (result) => {
        if (result) {
          var val = await this.deleteUserProfile(this.vDataTable.editedItem)
          if (val !== undefined) {
            this.vDataTable.selected.splice(this.vDataTable.selected.indexOf(this.vDataTable.editedItem), 1)
          }
          this.vDataTable.editedItem = Object.assign({}, this.vDataTable.defaultItem)
          this.vDataTable.editedIndex = -1
        }
      })
    },
    close () {
      this.dialog.dialogAddEditWindow = false
      this.$nextTick(() => {
        this.vDataTable.editedItem = Object.assign({}, this.vDataTable.defaultItem)
        this.vDataTable.editedIndex = -1
      })
    },
    async save () {
      if (this.validateReg() === true) {
        if (this.vDataTable.editedIndex > -1) {
          await this.updateUserProfile(this.vDataTable.editedItem.profile_skey,
            this.vDataTable.editedItem.profile_name,
            this.vDataTable.editedItem.abbreviation)
        } else {
          await this.addUserProfile(this.vDataTable.editedItem.profile_name,
            this.vDataTable.editedItem.abbreviation)
        }
        this.close()
      }
    },
    validateReg () {
      var messageCustom = ''
      if (!this.vDataTable.editedItem.profile_name) {
        /* messageCustom = messageCustom + his.$t('EgatWebEmployee.messageEmployeeID') + '<br />' */
        messageCustom = messageCustom + 'Please Enter Profile Name' + '<br />'
      }
      if (messageCustom) {
        this.$swal({type: 'error', html: messageCustom})
        return false
      } else {
        return true
      }
    },
    resetPassword () {
      this.$swal({
        text: 'Reset Password',
        type: 'question',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: this.$t('yes'),
        cancelButtonText: this.$t('no')
      }).then((result) => {
        if (result) {
          this.dialog.dialogProgress = true
          this.progressMessage = this.$t('waiting')
          this.axios.post(process.env.VUE_APP_API + '/user/resetPassword' + process.env.VUE_APP_DATABASE_TYPE)
            .then(response => {
              if (response.data.isSuccess) {
                this.dialog.dialogProgress = false
                this.$swal({type: 'success', title: response.data.reasonText, showConfirmButton: false, timer: 1500})
              } else {
                this.dialog.dialogProgress = false
                this.$swal({type: 'error', title: response.data.reasonText})
              }
            })
            .catch(e => {
              this.dialog.dialogProgress = false
              this.$swal({type: 'error', title: e.message})
            })
        }
      })
    },
    newItemLevel2 (item) {
      this.profileSkey = parseInt(item.profile_skey)
      var val = this.profileSkey
      var index = -1
      var filteredObj = this.vDataTable.data.find(function (items, i) {
        if (items.profile_skey === val) {
          index = i
          return i
        }
      })
      this.profileSkeyIndex = index
      /* this.editedItemLevel2 = Object.assign({}, item.Resource_Group_Details) */
      this.dialog.dialogBrowseUserGroupBrowse = true
      this.$refs.UserGroupBrowse.retrieveUserGroup()
    },
    async getVarReturn (value) {
      switch (value.childSend) {
        case 'UserGroupBrowse':
          this.dialog.dialogBrowseUserGroupBrowse = false
          switch (value.actionReturn) {
            case 'OK':
              var messageCustom = ''
              for (let dataCheck of value.dataSelected) {
                if (this.vDataTable.data[this.profileSkeyIndex].pl_profile_group.find(function (x) {
                  return (x.group_skey === dataCheck.group_skey)
                }) !== undefined) {
                  messageCustom = messageCustom + this.stringFormat('Duplicate ', dataCheck.group_name) + '<br />'
                }
              }
              if (messageCustom) {
                this.$swal({type: 'error', html: messageCustom})
                return false
              }
              for (let data of value.dataSelected) {
                if (this.vDataTable.data[this.profileSkeyIndex].pl_profile_group.find(function (x) {
                  return (x.group_skey === data.group_skey)
                }) === undefined) {
                  if (this.editedIndexLevel2 > -1) {
                    this.editedItemLevel2.group_skey = data.group_skey
                    this.editedItemLevel2.group_name = data.group_name
                    await this.saveLevel2()
                  } else {
                    var Level2Index = this.vDataTable.data[this.profileSkeyIndex].pl_profile_group.push({
                      'group_skey': data.group_skey,
                      'group_name': data.group_name}) - 1
                    this.editedItemLevel2 = Object.assign({}, this.vDataTable.data[this.profileSkeyIndex].pl_profile_group[Level2Index])
                    await this.saveLevel2()
                  }
                } else {
                  this.$swal({type: 'error', title: this.stringFormat('Group {} is already', data.group_name)})
                }
              }
          }
          break
      }
    },
    findNodeLevel2 (profileSkey) {
      return this.vDataTable.data.find(function (x) {
        return (x.profile_skey === profileSkey)
      }).selectedLanguage
    },
    findNodeSelectedLevel2 (profileSkey) {
      return this.vDataTable.data.find(function (x) {
        return (x.profile_skey === profileSkey)
      }).selectedLanguage
    },
    deleteItemLevel2 (item) {
      this.deletedIndexLevel2 = this.findNodeLevel2(item.profile_skey).indexOf(item)
      this.deletedItemLevel2 = Object.assign({}, item)
      this.$swal({
        text: 'คุณต้องการที่จะลบ Group ใช่หรือไม่',
        type: 'question',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: this.$t('yes'),
        cancelButtonText: this.$t('no')
      }).then((result) => {
        if (result) {
          this.deleteLevel2(item.profile_skey)
        }
      })
    },
    async deleteLevel2 (profileSkey) {
      try {
        this.dialog.dialogProgress = true
        this.progressMessage = this.$t('waiting')
        var groupSkey = this.deletedItemLevel2.group_skey
        await this.axios.post(process.env.VUE_APP_API + '/user/deleteUserProfileGroup' + process.env.VUE_APP_DATABASE_TYPE, {
          profile_skey: profileSkey,
          group_skey: groupSkey
        })
          .then(async response => {
            if (response.data.isSuccess) {
              this.findNodeLevel2(profileSkey).splice(this.deletedIndexLevel2, 1)
              this.retrieveUserProfile()
              this.$swal({type: 'success', title: response.data.reasonText, showConfirmButton: false, timer: 1500})
            } else {
              await this.$swal({type: 'error', title: response.data.reasonText})
            }
          })
          .catch(async e => {
            await this.$swal({type: 'error', title: e.message})
          })
      } catch (e) {
        await this.$swal({type: 'error', title: e.message})
      } finally {
        this.dialog.dialogProgress = false
      }
    },
    checkDeleteselectedLevel2 (item) {
      if (this.findNodeSelectedLevel2(item.profile_skey).length > 0) {
        this.$swal({
          text: 'คุณต้องการที่จะลบ Group2 ใช่หรือไม่',
          type: 'question',
          showCancelButton: true,
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',
          confirmButtonText: this.$t('yes'),
          cancelButtonText: this.$t('no')
        }).then((result) => {
          if (result) {
            this.deleteLevel2Selected(item)
          }
        })
      } else {
        this.$swal({type: 'warning', title: 'กรุณาเลือก Group ที่ต้องการลบ'})
      }
    },
    async deleteLevel2Selected (item) {
      this.dialogProgress = true
      this.progressMessage = this.$t('waiting')
      await this.asyncForEach(this.findNodeSelectedLevel2(item.profile_skey), async function (x) {
        this.progressMessage = 'Deleting'
        var profileSkey = item.profile_skey
        var groupSkey = x.group_skey
        await this.axios.post(process.env.VUE_APP_API + '/user/deleteUserProfileGroup' + process.env.VUE_APP_DATABASE_TYPE, {
          profile_skey: profileSkey,
          group_skey: groupSkey
        })
          .then(async response => {
            if (response.data.isSuccess) {
              this.$swal({type: 'success', title: response.data.reasonText, showConfirmButton: false, timer: 1500})
            } else {
              await this.$swal({type: 'error', title: response.data.reasonText})
            }
          })
          .catch(async e => {
            await this.$swal({type: 'error', title: e.message})
          })
      }.bind(this))
      this.dialogProgress = false
      /* this.retrieveNodeDef() */
      this.retrieveUserProfile()
    },
    async saveLevel2 () {
      await this.addLevel2(this.editedItemLevel2.group_skey, this.editedItemLevel2.group_name)
    },
    async addLevel2 (groupSkey, groupName) {
      var profileSkey = this.vDataTable.data[this.profileSkeyIndex].profile_skey
      await this.axios.post(process.env.VUE_APP_API + '/user/addProfileGroup' + process.env.VUE_APP_DATABASE_TYPE, {
        profile_skey: profileSkey,
        group_skey: groupSkey,
        group_name: groupName
      })
        .then(response => {
          if (response.data.isSuccess) {
            /* this.editedItemLevel2.Resource_Group_Detail_Id = response.data.data.identity_key */
            this.$swal({type: 'success', title: response.data.reasonText, showConfirmButton: false, timer: 1500})
          } else {
            this.$swal({type: 'error', title: response.data.reasonText})
          }
        })
        .catch(e => {
          this.$swal({type: 'error', title: e.message})
        })
      this.initialize()
      this.retrieveUserProfile()
    },
    async asyncForEach (array, callback) {
      for (let index = 0; index < array.length; index++) {
        await callback(array[index], index, array)
      }
    },
  }
}
</script>

<style lang="css">
.dialog.centered-dialog,
.v-dialog.centered-dialog
{
  background: #282c2dad;
  box-shadow: none;
  border-radius: 6px;
  width: auto;
  color: whitesmoke;
},
.DataTable
{
    display: none;
    border: none;
},
.rounded-card
{
  display: none;
  border: none;
}
</style>